<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from" @submit.native.prevent>

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="分类名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入分类名称"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { documentActivityPhotoCategoryList } from "../../api/activity-album/category-list";

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page };
      documentActivityPhotoCategoryList(postData).catch((err) => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>